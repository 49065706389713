import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import accountClient from 'api/accountClient';
import { graphql, navigate } from 'gatsby';
import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { getAzureChangeEmailUrl } from 'helpers/azureRoutingHelper';
import { accountRoutes } from 'helpers/routingHelper';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { useUser } from 'state/user/state';
import { CsMeta } from 'types/contentStack';

type ChangeEmailProps = {
  data: {
    csChangeEmail: {
      meta: CsMeta;
    };
  };
};

export const query = graphql`
  query {
    csChangeEmail {
      meta {
        meta_title
      }
    }
  }
`;

const ChangeEmail: React.FC<ChangeEmailProps> = ({
  data: {
    csChangeEmail: { meta },
  },
}) => {
  const [user] = useUser();
  const { requestHandler } = useApiRequestHandler();

  useEffect(() => {
    if (!user) {
      return;
    }
    if (!user.isLoggedIn) {
      navigate(accountRoutes.login);
      return;
    }
    requestHandler(() =>
      accountClient.getNonce().then(({ nonce }) => {
        window.location.replace(getAzureChangeEmailUrl(nonce));
      })
    );
  }, [requestHandler, user]);

  return (
    <Layout meta={meta} pageType="accountArea">
      <LoadingOverlay loadingMessage="Redirecting to change email page" />
    </Layout>
  );
};

export default ChangeEmail;
